/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  if (process.env.NODE_ENV === `production` && window.FB) {
    window.FB.Event.subscribe("customerchat.dialogShow", () => {
      window.dataLayer.push({ event: "FB_Messager_Button_Click" })
    })
  }
}

exports.onRouteUpdate = function ({ location }) {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production`) {
    if (window.fbq && typeof fbq === `function`) {
      window.fbq("track", "ViewContent")
    }
  }

  // Hide chat
  if (
    process.env.NODE_ENV === `production` &&
    location.pathname === "/ecommercequiz/"
  ) {
    const fbChat = document.getElementById("fb-root")
    if (fbChat) {
      fbChat.style.display = "none"
    }
  }
}
