// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-d-biz-js": () => import("./../src/pages/d-biz.js" /* webpackChunkName: "component---src-pages-d-biz-js" */),
  "component---src-pages-ecommercequiz-js": () => import("./../src/pages/ecommercequiz.js" /* webpackChunkName: "component---src-pages-ecommercequiz-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-blockchain-js": () => import("./../src/pages/services/blockchain.js" /* webpackChunkName: "component---src-pages-services-blockchain-js" */),
  "component---src-pages-services-ecommerce-js": () => import("./../src/pages/services/ecommerce.js" /* webpackChunkName: "component---src-pages-services-ecommerce-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-web-app-js": () => import("./../src/pages/services/mobile-web-app.js" /* webpackChunkName: "component---src-pages-services-mobile-web-app-js" */),
  "component---src-pages-services-shopify-js": () => import("./../src/pages/services/shopify.js" /* webpackChunkName: "component---src-pages-services-shopify-js" */),
  "component---src-pages-services-website-development-js": () => import("./../src/pages/services/website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-js" */),
  "component---src-pages-services-wordpress-js": () => import("./../src/pages/services/wordpress.js" /* webpackChunkName: "component---src-pages-services-wordpress-js" */),
  "component---src-pages-showcase-detail-js": () => import("./../src/pages/showcase-detail.js" /* webpackChunkName: "component---src-pages-showcase-detail-js" */),
  "component---src-pages-showcase-js": () => import("./../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-wordpress-security-js": () => import("./../src/pages/wordpress-security.js" /* webpackChunkName: "component---src-pages-wordpress-security-js" */)
}

